<template>
    <section class="payment-record"  @click.stop="pickerclick">
        <div class="top-bar bg-white">
            <el-input v-model="formSearch.keyword" style="margin: 0 10px; width: 250px" placeholder="请输入关键字搜索"
                      size="small"/>
            <el-date-picker @change="pickerclickitr"  ref="startAndEndDateRef" v-model="formSearch.startDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                            style="margin: 0 10px; width: 250px" size="small" placeholder="开始时间"/>
            <el-date-picker @change="pickerclickitr"  ref="billStartAndEndDateRef" v-model="formSearch.endDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                            style="margin: 0 10px; width: 250px" size="small" placeholder="结束时间"/>
            <!-- <el-select ref="subMchidRef" v-model="formSearch.subMchid" size="small" placeholder="请输入账单号简称" style="margin: 0 10px;">
                <el-option v-for="item in serviceList" :key="item.uuid" :label="item.codeName" :value="item.dataValue"/>
            </el-select> -->
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
            <!-- <el-button type="primary" size="small" @click="makeExport">导出数据</el-button> -->
        </div>

        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getOrderTableData" :columns="tableColumn"
                   :query="formSearch" tooltip-effect="light" :height="760" show-summary :summary-method="getSummaries">
            <template slot="empty">
                <el-empty/>
            </template>
            <!--<el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <el-button type="danger" v-if="row.statusEnum==='paid'" size="mini" @click="refund(row)">退款</el-button>
                    </div>
                </template>
            </el-table-column>-->
        </r-e-table>
        <!--<r-e-dialog :title="title" :visible.sync="billSwitch" show-footer top="5vh" width="1000px" :showFooter="false"
                    @click-cancel="billListClose" v-dialog-drag>
            <r-e-table ref="tableRef" :columns="houseBillColumns" :data="billList" :height="500" :showPagination="false"
                       show-summary :summary-method="getSummaries">
                <el-table-column prop="comment" label="账单备注" :show-overflow-tooltip="true"/>
            </r-e-table>
        </r-e-dialog>-->
    </section>
</template>

<script>
    import axios from "axios";
    import {tableColumn} from "./data";
    import {getOrderList, refund, makeExport, getSubclassList} from "@/api/order";
    import {getCommunityListApi} from "@/api/publish";
    import {MessageSuccess} from "@custom/message";
    import {downloadByData} from "@/utils/hooks/download";
    import {timeFormat, numberFormat} from "@custom/index";
    export default {
        name: "property-charges",
        data() {
            return {
                itr:true,
                tableColumn,
                tableData: [],
                communityList: [],
                paymentChannels: [],
                formSearch: {
                    subMchid: null,
                },
                serviceList: []
            };
        },
        async mounted() {
            // this.getServiceList();
            getCommunityListApi().then(res => this.communityList = res.list);
            // 获取商户号查询列表
            getSubclassList({code:800100}).then(res=> {
                let {list} = res;
                this.serviceList = list;
            });
            this.paymentChannels = await this.$store.dispatch("app/getDictionaryByCodeActions", "500100");
        },
        methods: {
            getOrderTableData(params) {
                return getOrderList(params);
            },
            handleSearch() {
                this.$refs.orderTableRef.pNumber = 1;
                this.$refs.orderTableRef.getTableData();
            },
            handleReset() {
                this.formSearch = {};
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            refund(row) {
                let that = this;
                this.$confirm('此操作将退款,确定是否退款?', '提示', {confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {
                    let {transactionId, total} = row;
                    const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                    refund({transactionId, amount: total}).then(res => {
                        MessageSuccess('退款成功');
                        this.$refs.orderTableRef.getTableData();
                    }).finally(() => loading.close());
                }).catch(() => {});
            },


            makeExport() {

                const loading = this.$loading({lock: true, text: "正在导出请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                let {keyword, startDate, endDate,subMchid} = this.formSearch;
                let paramData = {keyword, startDate, endDate,subMchid};
                makeExport(paramData).then(res => {
                    downloadByData({data: res, filename: `支付流水${timeFormat(new Date(),'yyyy-MM-dd-HH-mm-ss')}.xls`});
                    loading.close();
                }).catch(err => loading.close());
            },

            getSummaries(param) {
                // const { columns, data } = param;
                // const sums = [];
                // columns.forEach((column, index) => {
                //     if (index === 0) {
                //         sums[index] = '合计';
                //         return;
                //     }
                //
                //     if (index !== 7 && index !== 8) {
                //         sums[index] = '';
                //         return;
                //     }
                //     const values = data.filter(item => item.statusEnum === 'paid').map(item => Number(item[column.property]));
                //     if (!values.every(value => isNaN(value))) {
                //         sums[index] = values.reduce((prev, curr) => {
                //             const value = Number(curr);
                //             if (!isNaN(value)) return prev + curr;
                //             else return prev;
                //         }, 0);
                //         sums[index] = numberFormat(sums[index] / 100);
                //     } else sums[index] = '';
                //
                // });
                // return sums;
            },

            getServiceList(){
                let that = this;
                const options = {
                    method: 'POST',
                    url: '/oauth2/token',
                    params: {grant_type: 'client_credentials', scope: 'order'},
                    headers: {
                        'content-type': 'multipart/form-data;',
                        "Authorization":"Basic Ym13OnNlY3JldFA="
                    },
                };

                axios.request(options).then(response => {
                    let {data:{access_token,token_type}} = response;
                    const config = {
                        method: 'GET',
                        url: '/mircopay/service/order/query_merchant_relation_info',
                        params: {},
                        headers: {
                            'content-type': 'multipart/form-data;boundary=---011000010111000001101001;application/x-www-form-urlencoded',
                            "Authorization": token_type + " " + access_token,
                        },
                        changeOrigin: true
                    };
                    axios.request(config).then(res => {
                        let {data:{data:{list}}} = res;
                        that.serviceList = list;
                    }).catch(error => {
                        console.error(error);
                    });
                }).catch(error => {
                    console.error(error);
                });
            },
          // 2024-4-18-新增点击空白关闭组件
          pickerclick(e){
            console.log( this.itr)
            this.itr = !this.itr;
            if(this.itr){
              this.$refs['startAndEndDateRef'].hidePicker();
              this.$refs['billStartAndEndDateRef'].hidePicker();
            }
            this.$refs.subMchidRef.blur()
          },
          pickerclickitr(){
            this.itr = true;
            console.log(this.itr)
          },
        }
    };
</script>

<style lang="scss" scoped>
    .payment-record {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: flex-start;
        }

        /deep/ .r-e-table {
            .el-table .cell {
                /*white-space: nowrap;*/
                text-align: center;
            }
        }
    }
</style>